.container {
  --bs-gutter-x: 1.9rem;
  margin-top: -5px;
}
@media (min-width: 576px) {
  .container {
    max-width: none;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: none;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: none;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: none;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
#portal-column-one {
  width: auto !important;
}

#portal-column-two {
  width: auto !important;
}

#sidebar_right #portal-column-two {
  padding: 0;
}

#sidebar_right .portletNews {
  border: 0;
}

.portletNews .card-header {
  background-color: unset;
}

.portletNews .card-body {
  margin-left: 0;
}

.portletNews .card-footer {
  border-top: 0;
}

#portal-column-two .portletLanguageSelect {
  overflow: visible;
}

#content #portal-breadcrumbs {
  background-color: #ffffff !important;
}

.hu-base-col-xs-20 #content #viewlet-below-content .managePortletsLink
{
  display: none;
}

.layout-box #section-byline .badge {
  font-size: 13px;
}

#content-core .item #section-byline {
  display: none;
}

#footer-center .layout-box #section-byline {
  font-size: 80%;
}

#portal-breadcrumbs {
  margin-bottom: 0;
}

.breadcrumb {
  display: inline;
  padding-left: 0;
  padding-right: 0;
}

#content-core {
  padding-top: 0;
}

.btn-primary, .btn-secondary, .btn-warning {
  font-size: 12.8px;
}

article > header {
  margin-bottom: 0 !important;
}

hr {
  opacity: 1;
}

#content > header .lead {
  color: #666666;
  font-size: 1em;
  font-style: italic;
}

#section-leadimage {
  display: none;
}

.image-left {
  float: left;
}

.image-right {
  float: right;
}

input[type="text"] {
  display: block;
  width: 100%;
  font-size: 12.8px;
}


.ui-icon-circle-triangle-w {
  background-image: url('../images/arrow-circle-left-solid.svg');
  display: block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  text-indent: -9999px;
}


.ui-icon-circle-triangle-e {
  background-image: url('../images/arrow-circle-right-solid.svg');
  display: block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  text-indent: -9999px;
}


/*mosaic*/

.mosaic-tile-content article > div > div:nth-child(2) {
  display: none;
}

@media (min-width: 768px) {
  .mosaic-grid-row > .mosaic-width-half {
    width: 50%;
  }
}

.mosaic-tile-content h1:first-child, .mosaic-tile-content h2:first-child, .mosaic-tile-content h3:first-child, .mosaic-tile-content h4:first-child, .mosaic-tile-content h5:first-child, .mosaic-tile-content h6:first-child {
    margin-top: .7em !important;
}


/*tinymce*/

p.callout {
  background: #eee;
  padding: 1em;
  border-left: 1em solid #ccc;
  clear: both;
}