#visual-portal-wrapper {
  background-color: $hu-backdrop-grey;
}
body#visual-portal-wrapper {
	overflow: auto !important;
}

#main-container {
  padding-bottom: 1em;
}

input[type="text"], input[type="number"], input[type="email"], input[type="password"], textarea, select {
    //height: 24px;
}

input[type="radio"], input[type="checkbox"] {
    margin: 0;
}

textarea:focus, input:focus {
    outline: none !important;
}

#content-core {
    padding: .5em 0;
    > ul, > ol, > table {
        clear: both;
    }
}

figure.captioned {
	/*max-width: min-content; //commented out image within get only width of longest caption text element*/
}
figcaption {
    margin-left: 0 !important;
	margin: 0;
    padding-left: 0;
    padding-top: 3px;
    white-space: normal;
    max-width: 100%;
    display: block;
    text-align: left;
    color: #666;
    font-size: 85%;
}
h3 > figure > figcaption {
	font-size: 60%;
}

/* abgerundete Bilder */
.round {
    border-radius: 50%;
}
.rounded {
    border-radius: 5%;
}

input[type="submit"], button, select {
    //padding: 3px 12px;
    //height: 24px;
}

.searchPage .actionMenu dt {
    position: relative;
}
.searchPage .actionMenu dd {
    top: 50px;
}

.mce-window {
	position: absolute;
}
.mce-btn-group:not(:first-child) {
	margin-left: 0;
}

div.linkModal div.linkTypes input {
	margin: 0;
}

.upload-container #fakeUploadFile {
    height: auto;
}

/* Definition für Linksymbole */
.link-external
{
    background-image: url('++resource++humboldt.theme.images/leave.png');
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
}
.link-pdf
{
    background-image: url('++resource++humboldt.theme.images/pdf.png');
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
}
.link-download
{
    background-image: url('++resource++humboldt.theme.images/down.png');
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
}
.link-excel
{
    background-image: url('++resource++humboldt.theme.images/doc.png');
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
}
.link-word
{
    background-image: url('++resource++humboldt.theme.images/doc.png');
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
}
.link-image
{
    background-image: url('++resource++humboldt.theme.images/png.png');
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
}
.link-movie
{
    background-image: url('++resource++humboldt.theme.images/movie.png');
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
}
.language-de
{
    background-image: url('++resource++humboldt.theme.images/de.png');
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
}
.language-en
{
    background-image: url('++resource++humboldt.theme.images/gb.png');
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
}
/* Ende Linksymbole */

table {
    border: none;
}

dl.horizontal dd {
    display: inline-block;
}

.alert.status, .portalMessage {
    box-shadow: none;
	border-radius: 0;
}

.alert.statusmessage-warning {
    display: none;
}

#sidebar_right {
	margin-top: .4rem;
}

.portlets-manager.pat-manage-portlets {
	margin-right: 0;
	margin-left: 0;
}
