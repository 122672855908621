/*
#portal-logo img {
    filter: invert(60%) sepia(75%) saturate(503%) hue-rotate(62deg) brightness(93%) contrast(91%);
}
*/

.navTreeItem svg.plone-icon {
  display: none;
}

#edit-bar #edit-zone .toolbar-header {
  background-color: rgb(0, 88, 128);
}

.dropdown-menu .dropdown-header {
  color: #ccc!important;
}

h2 > nav {
  font-size: 75%;
}

nav ul.mode_selector {
  font-size: 14.8px;
  margin-left: 0.9em;
  margin-bottom: 0.5em;
}

article .vevent div.cal_info.clearfix {
  display: table-cell;
}