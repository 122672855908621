/* --------------------------------------------------------------

   typography.css
   * Sets up some sensible default typography.
   Dient der Text-Formatierung im Content-Bereich!

-------------------------------------------------------------- */

/* Default font settings.
   The font-size percentage is of 16px. (0.75 * 16px = 12px) */
html {
  font-size: 100%;
}
body {
  font-size: 80%;
  font-family: Verdana, Helvetica, Arial, sans-serif !important;
  margin-bottom: 6rem;
  overflow-wrap: anywhere;
  /*hyphens: auto;*/
}
@media only screen and (max-width: $plone-grid-float-breakpoint) {
  body {
    margin-bottom: 9rem;
  }
}
/*
#portal-column-one dl.portlet div.portletWrapper,
#portal-column-two dl.portlet { font-size: 85%; }
*/

/* Headings
-------------------------------------------------------------- */

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
}

h1 {
  font-size: 160%;
}
h2 {
  font-size: 155%;
}
h3 {
  font-size: 140%;
  border-bottom: none;
}
h4 {
  font-size: 130%;
  border-bottom: none;
}
h5 {
  font-size: 120%;
  border-bottom: none;
}
h6 {
  font-size: 100%;
  border-bottom: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-weight: normal;
  margin-top: 0.7em;
  margin-bottom: 0.3em;
  padding: 0;
}

h1 img,
h2 img,
h3 img,
h4 img,
h5 img,
h6 img {
  margin: 0;
}

.block-title {
  display: block;
  background: #ccd6e0 no-repeat;
}
h2.block-title {
  margin-left: -0.65em;
  margin-right: -0.65em;
  padding-left: 0.65em;
  padding-right: 0.65em;
}
h3.block-title {
  margin-left: -0.75em;
  margin-right: -0.75em;
  padding-left: 0.75em;
  padding-right: 0.75em;
}
h4.block-title {
  margin-left: -0.8em;
  margin-right: -0.8em;
  padding-left: 0.8em;
  padding-right: 0.8em;
}
h5.block-title {
  margin-left: -0.9em;
  margin-right: -0.9em;
  padding-left: 0.9em;
  padding-right: 0.9em;
}
h6.block-title {
  margin-left: -1em;
  margin-right: -1em;
  padding-left: 1em;
  padding-right: 1em;
}

nav.autotoc-nav h5 {
  margin: 0 1em .2em .7em;
  text-transform: uppercase;
}

table {
  font-size: 100%;
}

img {
  border: none;
  vertical-align: middle;
}

img.tile {
  width: 104px;
  height: 60px;
}
img.row {
  width: 205px;
  height: 95px;
}

.flexbin {
  display: block;
}

.flexbin.flexbin-margin {
  margin: 0;
}

.flexbin > * {
  height: auto;
  display: inline;
}

.flexbin > * > img {
  height: 120px !important;
  min-width: auto;
  float: left;
  margin-right: .7rem;
  margin-bottom: .7rem;
}

p {
  margin: 0 0 0.5em 0;
  line-height: 1.3em;
}
p img {
  border: none;
  margin: 0;
}
hr {
  height: 1px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 1em;
  margin-left: 0;
  border-style: none;
}
#content hr {
  margin-right: -1em;
  margin-bottom: 0.5em;
  margin-left: -1em;
  border-style: none;
}

body.frontend #content ul {
  line-height: 1.3em;
  /*margin: 0 0 0.5em 1.2em;*/
  padding: 0;
  list-style-image: url('++resource++humboldt.theme.images/bullet.gif');
  list-style-type: square;
  list-style-position: outside;
  li {
    margin-left: 0.9em;
  }
}
#content ol {
  line-height: 1.3em;
  //margin: 0 0 0.5em 1.6em;
  margin-left: .6em;
  padding: 0;
  list-style-image: none;
  list-style-position: outside;
  li {
    margin-left: 0.9em;
  }
}
li {
  margin-bottom: 0.3em;
}
body.frontend #content ul.pagination {
  list-style: none outside none;
}

dt {
  font-weight: bold;
}
dd {
  line-height: 1.3em;
  margin-bottom: 1em;
}
fieldset {
  //border: 1px solid #ddd;
  margin: 1em 0em;
  //padding: 0em 1em 1em;
  line-height: 1.3em;
  width: auto;
}
legend {
  padding: 0.5em;
  font-size: 90%;
}
form {
  border-style: none;
  margin: 0;
  padding: 0 !important;
}
label {
  font-weight: bold;
  span.required::after {
    content: "*";
    font-size: 100%;
    top: 0;
  }
  input.required::after {
    content: none;
  }
}

label.seo {
  display: none;
}
#content label {
  vertical-align: middle;
}
textarea {
  font: 100% Monaco, 'Courier New', Courier, monospace;
  border-width: 1px;
  border-style: solid;
  /* '/input_background.gif' has been deprecated and leads to 404 errors
     with current versions of CMFPlone */
  /*  background-image: url(/input_background.gif); */
  background-repeat: repeat-x;
  width: 100%;
}
input {
  visibility: visible;
  border-width: 1px;
  border-style: solid;
  vertical-align: middle;
  /* background-image: url(/input_background.gif);
	background-repeat: repeat-x;
	has been deprecated (sebastian jordan, 23.6.2013*/
}
button {
  visibility: visible;
  border-width: 1px;
  border-style: solid;
  vertical-align: middle;
  padding: 1px;
  cursor: pointer;
  font-size: 85%;
  text-transform: none;
}
select {
  border-width: 1px;
  border-style: solid;
  vertical-align: top;
  width: 100%;
}
optgroup {
  font-style: normal;
  font-weight: bold;
  padding-left: 0.25em;
}
optgroup > option {
  padding: 0 0.25em 0 1em;
}
abbr,
acronym,
.explain {
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  cursor: help;
}
q {
  font-family: Baskerville, Georgia, serif;
  font-style: italic;
  font-size: 120%;
}
blockquote {
  margin-left: 1.5em;
}
code {
  font-family: Monaco, 'Courier New', Courier, monospace;
  font-size: 120%;
  padding: 0 0.1em;
  color: #003366;
  background-color: transparent;
}
pre {
  font-family: Monaco, 'Courier New', Courier, monospace;
  font-size: 100%;
  padding: 1em;
  border-width: 1px;
  border-style: solid;
  overflow: auto;
}
ins {
  color: green;
  text-decoration: none;
}
del {
  color: red;
  text-decoration: line-through;
}

a {
  color: rgb(0, 102, 153);
  text-decoration: none;
}
a:focus {
  outline: 1px solid #ffa500;
}
a:visited {
  /*color: #4477aa;*/
}
a:hover {
  /*color: rgb(153, 153, 102);*/
  text-decoration: none;
}
#content-core a {
	border: none;
}
q {
  quotes: '“' '”' '‘' '’';
}

strong {
  font-weight: bold !important;
}
dfn {
  font-weight: bold;
}
em,
dfn {
  font-style: italic;
}
sup,
sub {
  line-height: 0;
}

abbr,
acronym {
  border-bottom: 1px dotted;
}
address {
  margin: 0 0 1.5em;
  font-style: italic;
}

pre {
  margin: 1.5em 0;
  white-space: pre;
}
pre,
code,
tt {
  font: 1em 'andale mono', 'lucida console', monospace;
  line-height: 1.5;
}

iframe {
  /*border: 1px solid #006699;*/
  border: none;
}

/* Lists
-------------------------------------------------------------- */
ul,
ol {
  margin: 0;
  padding: 0;
}
li ul,
li ol {
  margin: 0;
}

dl {
  margin: 0 0 1.5em 0;
}
dl dt {
  font-weight: bold;
}
dd {
  margin-left: 1.5em;
}

#catList {
  margin: 0.5em 0;
}

#letterList {
  margin: 0.5em 0 1.75em 0;
}

#letterList li {
  display: inline-block;
  font-size: 100%;
  font-weight: normal;
  padding-left: 0;
  padding-right: 1em;
  margin: 0 !important;
}

#catList li {
  margin: 0 0 0 1em;
}


/* Tables
-------------------------------------------------------------- */

table {
  margin-bottom: 1.4em;
  width: 100%;
  overflow-x: auto;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td,
table > caption + thead > tr:first-child > th, table > colgroup + thead > tr:first-child > th, table > thead:first-child > tr:first-child > th, table > caption + thead > tr:first-child > td, table > colgroup + thead > tr:first-child > td, table > thead:first-child > tr:first-child > td {
    border: 1px solid #e5e5e5;
}
table[border="0"] > thead > tr > th,
table[border="0"] > tbody > tr > th,
table[border="0"] > tfoot > tr > th,
table[border="0"] > thead > tr > td,
table[border="0"] > tbody > tr > td,
table[border="0"] > tfoot > tr > td,
table[border="0"] > caption + thead > tr:first-child > th, table[border="0"] > colgroup + thead > tr:first-child > th, table[border="0"] > thead:first-child > tr:first-child > th, table[border="0"] > caption + thead > tr:first-child > td, table[border="0"] > colgroup + thead > tr:first-child > td, table[border="0"] > thead:first-child > tr:first-child > td {
	border: none;
}
th {
  font-weight: bold;
}
th, td, caption {
  padding: 0.25em 0.5em;
}
td {
  vertical-align: top;
}
.ploneCalendar td {
  vertical-align: middle;
}

/* You can zebra-stripe your tables in outdated browsers by adding
   the class "even" to every other table row. */
tfoot {
  font-style: italic;
}

table.noborder tr th,
table.noborder tr td {
  border: none;
}

table.plain tr th,
table.plain tr td {
  border: none;
}

table.ygrid td,
table.ygrid th {
}
table.ygrid-border td,
table.ygrid.border th {
}
table.ygrid-border {
  border-style: hidden;
}
table.ngrid td,
table.ygrid th {
  border: none;
}
table.ngrid {
  border: 1px solid #000000;
}

/* Misc classes
-------------------------------------------------------------- */

.small {
  font-size: 0.8em;
  margin-bottom: 1.875em;
  line-height: 1.875em;
}
.large {
  font-size: 1.2em;
  line-height: 2.5em;
  margin-bottom: 1.25em;
}

.discreet {
  font-size: 85%;
  color: $hu-text-grey;
}
.hide {
  display: none;
}

.link-mailto {
}

.top {
  margin-top: 0;
  padding-top: 0;
}
.bottom {
  margin-bottom: 0;
  padding-bottom: 0;
}

.wichtig,
.important {
  color: #993333 !important;
  font-weight: bold;
}
.wichtigAbsatz,
.wichtigerAbsatz,
.portlet section.card-body .wichtigerAbsatz {
  border-left: 1em solid #ccd6e0;
  padding-left: 1em !important;
  margin-top: 1em;
  margin-bottom: 1em;
}
.marker-gelb {
  background-color: yellow;
}

.left,
.links {
  text-align: left;
}
.right,
.rechts {
  text-align: right;
}
.centered,
.zentriert {
  text-align: center;
}
.justified,
.blocksatz,
.text-justify {
  text-align: justify;
}

/* Klassen für die Portalansicht */
.kasten-aussen {
  min-height: 150px;
}
.kasten-aussen img, .kasten-aussen figure {
  float: left;
}
.kasten-aussen figure img {
  float: none;
}
.kasten-innen {
  padding-left: 1em;
  margin-left: 150px;
}
.kasten-innen .title,
.kasten-innen h4 {
  margin-top: 1px;
}

/* These can be used to pull an image at the start of a paragraph, so
   that the text flows around it (usage: <p><img class="left">Text</p>)  */
p .left,
p .links {
  float: left !important;
  margin: 0.4em 0.9em 0.25em 0;
  padding: 0;
}
p .right,
p .rechts {
  float: right !important;
  margin: 0.4em 0 0.25em 0.9em;
  padding: 0;
}

/* Spaltenlayout
   mit .erste bzw .letzte benutzen.
   zB class="zwei spalten erste" & class="zwei spalten letzte"
   im portal verwendbar durch zB class="drei spalten portal erste" */
.spalten, .theme-block {
  display: inline-block;
  vertical-align: top;
  padding-left: 3.3%;
  padding-right: 3.3%;
  margin-bottom: 1em;
}

.spalten.portal figure {
  width: 100%;
}

.spalten h3 {
  background-color: #ccd6e0;
  font-size: 120%;
  margin-left: -4%;
  margin-right: -4%;
  padding: 0.2em 0.2em 0.2em 4%;
}

.spalten img, .theme-block img {
  height: auto !important;
  max-width: 100%;
}

.zwei.spalten > a > .captioned, .drei.spalten > a > .captioned {
    width: 100% !important;
}

.spalten ul, .theme-block ul {
  margin: 0 !important;
}

.spalten li, .theme-block li {
  border-bottom: 1px solid #b2c1d1;
  list-style: none outside none;
  margin-left: -4% !important;
  margin-right: -4%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

.theme-block, .theme-block-with-image {
  padding-left: 1.7%;
  padding-right: 1.7%;
  margin-bottom: .3em;
  width: 30%;
}
.theme-block-block, .theme-block-with-image-block {
  background-color: rgb(204 214 224) !important;
  color: #003366  !important;
  margin-left: -4% !important;
  margin-right: -4% !important;
}
.theme-block-block {
  padding: .5em .7em;
}
.theme-block-with-image-block {
  padding: 0;
}
.theme-block-with-image-text {
  padding: .5em .7em;
}

.zwei {
  width: 49%;
  box-sizing: border-box;
}
.drei {
  width: 28%;
  box-sizing: content-box;
}

.first,
.erste {
  clear: left;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.last,
.letzte {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.zwei.letzte,
.zwei.last {
  position: relative;
  right: -1.5%;
}

.drei.letzte,
.drei.last {
  position: relative;
  right: -1.5%;
}

.drei.erste + .drei,
.drei.first + .drei {
  position: relative;
  right: -0.75%;
}
.drei.erste {
  float: none;
}

/* specific Textelelements for Plone
----------------------------------------------------------------- */
/* portal footer */
#portal-footer .documentByLine {
  font-size: 80%;
}

/*  */
#content .documentDescription,
#content #description {
  color: $hu-text-grey;
  font-weight: normal;
  font-style: italic;
  font-size: 1em;
  margin-bottom: 0.5em;
}
#content .discreet,
#sidebar .discreet {
  color: $hu-text-grey;
  font-size: 85%;
  font-weight: normal;
  /*text-align: center;*/
}
#content .description {
  text-align: left;
}

/* Ergaenzung LW */

/* frontpage styles */
.welcome {
  font-size: 120%;
}

#content ul.news-listing,
#content ul.events-listing {
  list-style-image: none !important;
  list-style-type: none !important;
  padding-left: 0;
  margin-left: 0;
}

#content .news-listing li,
#content .events-listing li {
  border: none;
  position: relative;
}

#content ul.news-listing li,
#content ul.events-listing li {
  margin: -4%;
}

.news-listing img,
.events-listing img {
  margin-top: 1em;
  width: auto !important;
}

.frontpageslider
  div.content
  div#content
  div.zwei.spalten.erste
  ul.news-listing
  li,
.frontpageslider
  div.content
  div#content
  div.zwei.spalten.letzte
  ul.events-listing
  li {
  border: none;
}

#content .news-listing img,
#content .events-listing img {
  margin-right: 1em;
  margin-bottom: 0.5ex;
  vertical-align: text-top;
}

#content .news-listing .title,
#content .events-listing .title {
  font-weight: bold;
  margin-bottom: 0px;
}

#content .events-listing .dates {
  font-weight: bold;
}

#content #collage .events-listing .dates {
  font-weight: normal;
}

#content .news-listing p.description,
#content .events-listing p.description {
  padding: 0;
}

#content .news-listing p.description a,
#content .events-listing p.description a {
  font-size: 70%;
  vertical-align: top;
}

#content hr.collage-list-item-separator {
  margin-left: 0px;
  margin-right: 0px;
}

.formControls,
.pat-autotoc.autotabs, .autotabs {
  box-shadow: none;
}

#lineageSelectionForm, .hiddenInViewSource {
  display: none;
}

.standalone, .context, .destructive, [type="submit"], button {
	text-shadow: none !important;
}

/* Print
-------------------------------------------------------------- */

.onlyprint { display: none; }

/* zl_temp comment out $page
$page {
	size: A4;
	margin: 3em 4em;
}
-- */

@media print {
	html,
	body,
	body * {
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
	}
	#main-container, #main-container * {
		visibility: visible;
	}
	#main-container {
		background-color: white;
		margin: 0;
		padding: 0;
		box-shadow: none;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}
	div#print-header.hide {
		display: block !important;
		width: 100%;
		text-align: right;
	}
	.onlyprint {
		display: block !important;
	}

    a[href]:after {
        content: none !important;
    }
	.noprint,
	div.header-menu,
	#sidebar,
	#sidebar_right,
	#audience-menu-wrapper,
	#viewlet-below-content,
	#newsletter-registration,
	#portal-anontools,
	#footer-left,
	#footer-center,
	#footer-right,
	#footer-analytics,
	#plone-analytics {
		display: none !important;
	}
	div.container, #content, #content-core {
		border: none;
		padding: 0 !important;
		width: 100%;
	}
	
	#print-header {
		text-align: right;
	}
	
	div.documentByLine {
		padding: 15px 0 !important;
	}
	
	.hu-base-row {
		margin: 0;
	}
	.hu-base-col-xs-1, .hu-base-col-sm-1, .hu-base-col-md-1, .hu-base-col-lg-1, .hu-base-col-xs-2, .hu-base-col-sm-2, .hu-base-col-md-2, .hu-base-col-lg-2, .hu-base-col-xs-3, .hu-base-col-sm-3, .hu-base-col-md-3, .hu-base-col-lg-3, .hu-base-col-xs-4, .hu-base-col-sm-4, .hu-base-col-md-4, .hu-base-col-lg-4, .hu-base-col-xs-5, .hu-base-col-sm-5, .hu-base-col-md-5, .hu-base-col-lg-5, .hu-base-col-xs-6, .hu-base-col-sm-6, .hu-base-col-md-6, .hu-base-col-lg-6, .hu-base-col-xs-7, .hu-base-col-sm-7, .hu-base-col-md-7, .hu-base-col-lg-7, .hu-base-col-xs-8, .hu-base-col-sm-8, .hu-base-col-md-8, .hu-base-col-lg-8, .hu-base-col-xs-9, .hu-base-col-sm-9, .hu-base-col-md-9, .hu-base-col-lg-9, .hu-base-col-xs-10, .hu-base-col-sm-10, .hu-base-col-md-10, .hu-base-col-lg-10, .hu-base-col-xs-11, .hu-base-col-sm-11, .hu-base-col-md-11, .hu-base-col-lg-11, .hu-base-col-xs-12, .hu-base-col-sm-12, .hu-base-col-md-12, .hu-base-col-lg-12, .hu-base-col-xs-13, .hu-base-col-sm-13, .hu-base-col-md-13, .hu-base-col-lg-13, .hu-base-col-xs-14, .hu-base-col-sm-14, .hu-base-col-md-14, .hu-base-col-lg-14, .hu-base-col-xs-15, .hu-base-col-sm-15, .hu-base-col-md-15, .hu-base-col-lg-15, .hu-base-col-xs-16, .hu-base-col-sm-16, .hu-base-col-md-16, .hu-base-col-lg-16, .hu-base-col-xs-17, .hu-base-col-sm-17, .hu-base-col-md-17, .hu-base-col-lg-17, .hu-base-col-xs-18, .hu-base-col-sm-18, .hu-base-col-md-18, .hu-base-col-lg-18, .hu-base-col-xs-19, .hu-base-col-sm-19, .hu-base-col-md-19, .hu-base-col-lg-19, .hu-base-col-xs-20, .hu-base-col-sm-20, .hu-base-col-md-20, .hu-base-col-lg-20, #content {
		padding: 0;
	}
}

/* spotlight special */
#spotlight .footer .title {
    color: white !important;
}
